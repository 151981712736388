import { createSlice } from '@reduxjs/toolkit'

export const menubarSlice = createSlice({
  name: 'menu',
  initialState: {
    open: false,
  },
  reducers: {
    toggle: (state) => {
      state.open = !state.open
    }
  },
})

export const { toggle } = menubarSlice.actions

export default menubarSlice.reducer