import React from 'react';
import { Footer } from '../../Components/Shared/Footer';
import Navbar from '../../Components/Shared/Navbar';
import Contact from '../Contact/Contact';
import About from './About';

const AboutPage = () => {
    return (
        <>
            <Navbar page></Navbar>
            <About></About>
            <Contact></Contact>
            <Footer></Footer>
        </>
    );
};

export default AboutPage;