export const Pricing = ({page}) => {

    const graphicsArray = ["Corporate Identity Design", "Logo Design", "Brand Design", "Info Graphics", "UI/UX", "Product Design", "Business Card", "Flyer"];

    const webArray = ["Corporate Website", "Portfolio Website", "E-Commerce Website", "Agency Website"];

    const mobileArray = ["Android Application", "IOS Application"];

    const digArray = ["Advertising Banner Design", "Animation Video", "Augmented Reality Advertising Content", "Interactive Advertising Content"];

    const softArray = ["Inventory Management Website", "School Management System", "Human Resources Management", "Customer Relationship Management", "AR/VR Applications", "Custom Software Development"];

    return (
        <div className={`relative w-full h-full  z-[-10] ${page && "mt-24"}`}>
            <div className="absolute hidden w-full bg-gray-50 lg:block h-96" />
            <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-400 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                                <defs>
                                    <pattern
                                        id="2c67e949-4a23-49f7-bf27-ca140852cf21"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#2c67e949-4a23-49f7-bf27-ca140852cf21)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Affordable</span>
                        </span>{' '}
                        for everyone
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        Whether you're in need of a custom software application, a website redesign, or an e-commerce platform, we have the skills and experience to bring your vision to life.
                    </p>
                </div>
                <div className="grid max-w-screen gap-5 md:grid-cols-5 sm:mx-auto">

                    <div>
                        <div className="p-8 bg-[#0044cc] rounded">
                            <div className="mb-4 text-center">
                                <p className="text-xl font-medium tracking-wide text-white">
                                    Software Development
                                </p>
                            </div>
                            <ul className="mb-8 space-y-2">
                                {
                                    softArray.map((g) => (
                                        <li key={g} className="flex items-center">
                                            <div className="mr-3">
                                                <svg
                                                    className="w-4 h-4 text-[#4CCEFF]"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                >
                                                    <polyline
                                                        fill="none"
                                                        stroke="currentColor"
                                                        points="6,12 10,16 18,8"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        fill="none"
                                                        r="11"
                                                        stroke="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="font-medium text-gray-300">{g}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365FF] focus:shadow-outline focus:outline-none"
                            >
                                Get Now
                            </button> */}
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-[#1365FF] rounded-b opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-[#3EB4FF] rounded-b opacity-50" />
                        <div className="w-9/12 h-2 mx-auto bg-[#4CCEFF] rounded-b opacity-25" />
                    </div>
                    <div>
                        <div className="p-8 bg-[#0044cc] rounded">
                            <div className="mb-4 text-center">
                                <p className="text-xl font-medium tracking-wide text-white">
                                    Digital Advertising Content
                                </p>
                            </div>
                            <ul className="mb-8 space-y-2">
                                {
                                    digArray.map((g) => (
                                        <li key={g} className="flex items-center">
                                            <div className="mr-3">
                                                <svg
                                                    className="w-4 h-4 text-[#4CCEFF]"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                >
                                                    <polyline
                                                        fill="none"
                                                        stroke="currentColor"
                                                        points="6,12 10,16 18,8"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        fill="none"
                                                        r="11"
                                                        stroke="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="font-medium text-gray-300">{g}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365FF] focus:shadow-outline focus:outline-none"
                            >
                                Get Now
                            </button> */}
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-[#1365FF] rounded-b opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-[#3EB4FF] rounded-b opacity-50" />
                        <div className="w-9/12 h-2 mx-auto bg-[#4CCEFF] rounded-b opacity-25" />
                    </div>

                    <div>
                        <div className="p-8 bg-[#0044cc] rounded">
                            <div className="mb-4 text-center">
                                <p className="text-xl font-medium tracking-wide text-white">
                                    Graphics Work
                                </p>
                            </div>
                            <ul className="mb-8 space-y-2">
                                {
                                    graphicsArray.map((g) => (
                                        <li key={g} className="flex items-center">
                                            <div className="mr-3">
                                                <svg
                                                    className="w-4 h-4 text-[#4CCEFF]"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                >
                                                    <polyline
                                                        fill="none"
                                                        stroke="currentColor"
                                                        points="6,12 10,16 18,8"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        fill="none"
                                                        r="11"
                                                        stroke="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="font-medium text-gray-300">{g}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365FF] focus:shadow-outline focus:outline-none"
                            >
                                Get Now
                            </button> */}
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-[#1365FF] rounded-b opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-[#3EB4FF] rounded-b opacity-50" />
                        <div className="w-9/12 h-2 mx-auto bg-[#4CCEFF] rounded-b opacity-25" />
                    </div>
                    <div>
                        <div className="p-8 bg-[#0044cc] rounded">
                            <div className="mb-4 text-center">
                                <p className="text-xl font-medium tracking-wide text-white">
                                    Web Development
                                </p>
                            </div>
                            <ul className="mb-8 space-y-2">
                                {
                                    webArray.map((g) => (
                                        <li key={g} className="flex items-center">
                                            <div className="mr-3">
                                                <svg
                                                    className="w-4 h-4 text-[#4CCEFF]"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                >
                                                    <polyline
                                                        fill="none"
                                                        stroke="currentColor"
                                                        points="6,12 10,16 18,8"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        fill="none"
                                                        r="11"
                                                        stroke="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="font-medium text-gray-300">{g}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365FF] focus:shadow-outline focus:outline-none"
                            >
                                Get Now
                            </button> */}
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-[#1365FF] rounded-b opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-[#3EB4FF] rounded-b opacity-50" />
                        <div className="w-9/12 h-2 mx-auto bg-[#4CCEFF] rounded-b opacity-25" />
                    </div>
                    <div>
                        <div className="p-8 bg-[#0044cc] rounded">
                            <div className="mb-4 text-center">
                                <p className="text-xl font-medium tracking-wide text-white">
                                    Mobile Applications
                                </p>
                            </div>
                            <ul className="mb-8 space-y-2">
                                {
                                    mobileArray.map((g) => (
                                        <li key={g} className="flex items-center">
                                            <div className="mr-3">
                                                <svg
                                                    className="w-4 h-4 text-[#4CCEFF]"
                                                    viewBox="0 0 24 24"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                >
                                                    <polyline
                                                        fill="none"
                                                        stroke="currentColor"
                                                        points="6,12 10,16 18,8"
                                                    />
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        fill="none"
                                                        r="11"
                                                        stroke="currentColor"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="font-medium text-gray-300">{g}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <button
                                type="submit"
                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365FF] focus:shadow-outline focus:outline-none"
                            >
                                Get Now
                            </button> */}
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-[#1365FF] rounded-b opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-[#3EB4FF] rounded-b opacity-50" />
                        <div className="w-9/12 h-2 mx-auto bg-[#4CCEFF] rounded-b opacity-25" />
                    </div>
                </div>
            </div>
        </div>
    );
};