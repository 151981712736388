import { GrLinkedin } from 'react-icons/gr'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <div className="bg-gray-900">
            <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
                    <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                        <div className='my-auto col-span-2 grid grid-cols-2'>
                            <img src="assets/logo-white.png" alt="" />
                        </div>
                        <div>
                            <p className="font-medium tracking-wide text-gray-300">
                                Office Adress
                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <p
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        House# 16 
                                    </p>
                                </li>
                                <li>
                                    <p
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Rd # 5A
                                    </p>
                                </li>
                                <li>
                                    <p
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Uttara Sector 11
                                    </p>
                                </li>
                                <li>
                                    <p
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Dhaka 1230
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p className="font-medium tracking-wide text-gray-300">
                                Menu
                            </p>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    <Link to="/"
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services"
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about"
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <a href="/contact"
                                        className="text-gray-500 transition-colors duration-300 hover:text-[#04c]"
                                    >
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="md:max-w-md lg:col-span-2">
                        <span className="text-base font-medium tracking-wide text-[#04c]">
                            Subscribe for updates
                        </span>
                        <form className="flex flex-col mt-4 md:flex-row">
                            <input
                                placeholder="Email"
                                required
                                type="text"
                                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-[#04c] rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-[#04c] focus:outline-none focus:shadow-outline"
                            />
                            <button
                                type="submit"
                                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#1365ff] hover:bg-[#04c] focus:shadow-outline focus:outline-none"
                            >
                                Subscribe
                            </button>
                        </form>
                        <p className="mt-4 text-sm text-gray-500">
                        Contact us today so we can grow and thrive.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row">
                    <p className="text-sm text-gray-500">
                        © Copyright 2020 UNIECH Inc. All rights reserved.
                    </p>
                    <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                        <a
                            href="https://www.facebook.com/uniech.official"
                            className="hover:text-[#04c] transition-colors duration-300 text-[#3EB4FF]"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                            </svg>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/uniech/"
                            className="hover:text-[#04c] transition-colors duration-300 text-[#3EB4FF]"
                        >
                            <GrLinkedin className='w-5 h-5'></GrLinkedin>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};