import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Hero = () => {

    const open = useSelector((state) => state.menubar.open)

    return (
        <div className={`flex  md:ml-0 gap-1 md:flex-row flex-col-reverse h-screen  bg-gradient-to-r from-[#4b4ef8] to-[#04c] ${open ? "pt-80" : "pt-2"} text-white items-center md:text-center duration-500 ease-in-out py-10`}>
            {/* <img className='absolute left-0 w-1/5 md:w-40 top-[280px] md:top-60 z-0' src="assets/bg-1.png" alt="" />
            <img className='absolute right-0 top-[200px] md:top-40 w-1/5 md:w-40 z-0' src="assets/bg-2.png" alt="" /> */}
            <div className='flex flex-col items-center'> <p className='underline text-sm font-medium'>
                Your trusted partner in technology solutions.
            </p>
                <h1 className='font font-semibold lg:text-7xl md:text-5xl sm:text-4xl transition-all duration-150 text-xl w-4/5 md:w-3/4 mt-3 md:text-current'>
                    Empowering Your Business with Innovative Technology.
                </h1>
                <p className='md:max-w-4xl w-4/5 text-xs md:text-base text-left md:text-center mt-5'>
                    UNIECH is a dynamic and forward-thinking information technology service provider that is dedicated to delivering exceptional services and solutions to its clients. With a team of highly skilled and experienced IT professionals, UNIECH is at the forefront of the industry, offering a comprehensive range of services that cater to the unique needs of businesses of all sizes.
                </p>
                <Link to={'/contact'} className='bg-white w-fit text-blue-600 py-2 px-4 rounded-md mt-10'>
                    Get Started
                </Link>
            </div>
            <div className='p-10 '>
                <img src="assets/group.png" alt="" />
            </div>

        </div>
    );
};

export default Hero;