import React from 'react'
import './about.css'

const About = () => {
    return (
        <section className='lg:mt-40 mt-20'>
            <div className='text-center'>
                <h1 className='md:text-4xl text-xl font-semibold'>
                    WHO WE ARE
                </h1>
                <hr className='md:w-1/4 w-1/2 mx-auto border-2 mt-1' />
            </div>
            <div className='mt-10 bg-image w-4/5 lg:w-3/4 mx-auto lg:h-[60vh] md:p-10 lg:p-16'>
                <div>
                    <p className='text-white mx-auto text-xl sm:text-lg md:text-base lg:text-xl md:leading-6 pt-10 p-5 md:p-0 md:pt-0 md:text-justify 2xl:text-3xl 2xl:leading-10'>
                    UNIECH is a dynamic and forward-thinking information technology service provider that is dedicated to delivering exceptional services and solutions to its clients. With a team of highly skilled and experienced IT professionals, UNIECH is at the forefront of the industry, offering a comprehensive range of services that cater to the unique needs of businesses of all sizes.
                    </p>
                </div>
                <img className='hidden md:inline w-full mt-5 shadow-lg rounded-2xl' src="assets/62.png" alt="" />
                <img className='md:hidden rounded-3xl inline w-full' src="assets/62-mobile.png" alt="" />
            </div>
            <div className='text-center mt-20 md:mt-40 2xl:mt-80'>
                <h1 className='md:text-4xl text-xl font-semibold'>
                    WHY US
                </h1>
                <hr className='md:w-1/4 w-1/2 mx-auto border-2 mt-1' />
            </div>
            <div className='md:px-20 px-10 mb-[-3rem] md:py-10 py-5 text-center 2xl:text-2xl md:text-xl text-lg text-slate-500'>
                <p>
                At UNIECH, we believe that our clients are the cornerstone of our business, and we are committed to providing them with the highest level of service and support. Here are just a few reasons why choosing UNIECH is the right choice for your business:
                
                <ul className='flex flex-col list-disc py-10 gap-3 text-start'>
                    <li> <strong>Expertise:</strong> Our team of IT professionals has the experience and knowledge necessary to deliver innovative and effective solutions that meet the unique needs of your business. Whether you're looking to upgrade your existing infrastructure, develop a new software application, or enhance your cybersecurity measures, we have the expertise to help you achieve your goals.</li>
                    <li><strong>Tailored Solutions:</strong>We understand that every business is unique, which is why we take a comprehensive and personalized approach to each of our projects. Our team works closely with you to understand your specific needs and challenges, and we develop customized solutions that are tailored to meet your requirements.</li>
                    <li><strong>Customer Focus:</strong> At UNIECH, we believe that our success is directly tied to the success of our clients, which is why we are dedicated to delivering the highest level of customer service and support. Our team is always available to assist you with any technical issue or question, and we work closely with you to ensure your complete satisfaction.</li>
                    <li><strong>Cutting-Edge Technology:</strong> We stay up to date with the latest trends and technologies in the IT industry, and we are committed to providing our clients with the most advanced and innovative solutions. Whether you're looking to adopt cloud computing, leverage artificial intelligence, or implement a new data management system, we have the expertise to help you succeed.</li>
                </ul>

                </p>
            </div>
        </section>
    )
}

export default About