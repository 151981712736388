import { GrLinkedin } from "react-icons/gr";

export const Content = ({page}) => {
    return (
        <div className={`px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ${page && "mt-24"}`}>
            <div className="grid gap-10 lg:grid-cols-2">
                <div className="lg:pr-10">
                    <a
                        href="/"
                        aria-label="Go Home"
                        title="Logo"
                        className="inline-block mb-5"
                    >
                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-[#4CCEFF]">
                            <svg
                                className="w-10 h-10 text-[#04c]"
                                stroke="currentColor"
                                viewBox="0 0 52 52"
                            >
                                <polygon
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="none"
                                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                                />
                            </svg>
                        </div>
                    </a>
                    <h5 className="mb-4 text-4xl font-extrabold leading-none">
                        At UNIECH, we improve the 
                        <br className="hidden md:block" />
                        <span className="inline-block text-[#04c]">
                            efficiency and effectiveness  {' '} 
                        </span>
                        of your business operations.
                    </h5>
                    <p className="mb-6 text-gray-900">At UNIECH, we believe that technology should be accessible to everyone, regardless of their size or budget. That's why we offer a comprehensive range of IT services, from cloud computing and cybersecurity to software development and data analytics. Our talented team of experts stays up to date with the latest technologies and trends to ensure that our clients always have access to the most advanced solutions.
                    </p>
                    <hr className="mb-5 border-gray-300" />
                    <div className="flex items-center space-x-4">
                        <a
                            href="https://www.facebook.com/uniech.official"
                            className="hover:text-[#04c] transition-colors duration-300 text-[#4CCEFF]"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                            </svg>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/uniech/"
                            className="hover:text-[#04c] transition-colors duration-300 text-[#4CCEFF]"
                        >
                            <GrLinkedin className='w-5 h-5'></GrLinkedin>
                        </a>
                    </div>
                </div>
                <div>
                    <img
                        className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                        src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};