import React from 'react';
import { Footer } from '../../Components/Shared/Footer';
import Navbar from '../../Components/Shared/Navbar';
import Contact from './Contact';

const ContactPage = () => {
    return (
        <>
            <Navbar page></Navbar>
            <Contact></Contact>
            <Footer></Footer>
        </>
    );
};

export default ContactPage;