import React from 'react';
import { Content } from './Content';
import Hero from './Hero';
import { JoinUs } from './JoinUs';
import { Pricing } from './Pricing';

const Home = () => {

    return (
        <>
            <Hero></Hero>
            <Content></Content>
            <Pricing></Pricing>
            <JoinUs></JoinUs>
        </>
    );
};

export default Home;