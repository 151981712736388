import Home from "./Components/Home/Home";
import { Footer } from "./Components/Shared/Footer";
import Navbar from "./Components/Shared/Navbar";
import 'react-toastify/dist/ReactToastify.min.css';

function App() {

  return (
    <div className="relative">
      <Navbar></Navbar>
      <Home></Home>
      <Footer></Footer>
    </div>
  );
}

export default App;
