import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from '../../store/features/menubar/menubarSlice';
import { Link } from 'react-router-dom';

const Navbar = ({ page }) => {

    const open = useSelector((state) => state.menubar.open)
    const dispatch = useDispatch()

    const [isScrolled, setIsScrolled] = useState(false)
    const [isScrolledDown, setIsScrolledDown] = useState(false)
    const [lastScrollY, setLastScrollY] = useState(0);
    const [height, setHeight] = useState(0);



    useEffect(() => {

        setHeight(window.innerHeight)

        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
            setLastScrollY(window.scrollY); 

            if (page) return

            if (window.scrollY > height) {
                setIsScrolledDown(true)
            } else {
                setIsScrolledDown(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }

    }, [page, lastScrollY, height])

    const menus = <>
        <li className='md:hover:bg-[#04c] hover:text-white cursor-pointer md:py-1 md:px-4 md:rounded-full md:duration-150'>
            <Link to={'/'}>Home</Link>
        </li>
        <li className='md:hover:bg-[#04c] hover:text-white cursor-pointer md:py-1 md:px-4 flex items-center md:justify-center gap-2 md:rounded-full md:duration-150'>
            <Link to={'/services'}>Services</Link>
        </li>
        <li className='md:hover:bg-[#04c] hover:text-white cursor-pointer md:py-1 md:px-4 md:rounded-full md:duration-150'>
            <Link to={'/about'}>About us</Link>
        </li>
        <li className='md:hover:bg-[#04c] hover:text-white cursor-pointer md:py-1 md:px-4 md:rounded-full md:duration-150'>
            <Link to={'/contact'}>Contact</Link>
        </li>
    </>
    // #1A56CE
    return (
        <header className='sticky lg:top-2 flex justify-center z-20'>
            <nav className={`${isScrolledDown ? "bg-[#e8f6fc]" : "bg-[#1A56CE]"} ${isScrolled && "  translate-y-[-120px] duration-1000"} absolute transition-all duration-150 drop-shadow-xl w-full lg:w-3/4 p-8 lg:mt-5 md:h-20 h-12 lg:rounded-lg text-white flex md:justify-between justify-start gap-x-4 items-center`}>

                {
                    open ? <AiFillCloseCircle color='#ffffff' onClick={() => dispatch(toggle())} size='1.5rem' className='block lg:hidden cursor-pointer mr-2'></AiFillCloseCircle> : <GiHamburgerMenu onClick={() => dispatch(toggle())} size='1.5rem' className='block lg:hidden cursor-pointer mr-2'></GiHamburgerMenu>
                }

                <ul className={`absolute bg-[#1A56CE] p-8 text-lg rounded-md lg:hidden ${open ? "left-2 top-20" : "left-[-500px] top-20"} duration-500 ease-in-out gap-y-5`}>
                    {menus}
                </ul>
                <div>
                <Link to={'/'}>
                    <img className='w-40' src={isScrolledDown ? "assets/logo.png" : "assets/logo-white.png"} alt="" /></Link>
                </div>
                <ul className={`lg:flex hidden px-16 py-1 rounded-full items-center justify-center gap-4 ${isScrolledDown && "text-[#04c]"}`}>
                    {menus}
                </ul>
                <div className='md:flex gap-2 items-center hidden '>
                    <Link to={'/contact'}>
                    <button className='bg-[#04c] p-2 rounded-lg'>Get Started</button>
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
