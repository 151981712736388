import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { MdOutlineEmail } from 'react-icons/md'
import { GrLinkedin } from 'react-icons/gr'
import { IoLocationOutline } from "react-icons/io5";
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <section className='md:mt-40 mt-20'>
      <div className='text-center'>
        <h1 className='text-4xl font-semibold'>
          Contact Us
        </h1>
        <p className='mt-2 text-gray-500'>
          Any question or remarks? Just write us a message!
        </p>
      </div>
      <div className='md:grid relative bg-[#5fd1fb10] md:w-3/4 mx-4 md:mx-auto p-2 rounded-lg border my-10 border-slate-600 border-opacity-20'>     

        <div className='bg-[#04c] relative text-white p-6 rounded-lg z-0'>
          <img className='absolute bottom-0 right-0 -z-10' src="assets/Ellipse 793.svg" alt="" />
          <img className='absolute bottom-[6rem] right-16' src="assets/Ellipse 794.svg" alt="" />
          <h2 className='font-semibold text-xl mt-4'>
            Contact Information
          </h2>
          <p className='mt-2'>
            Say something to start a live chat!
          </p>
          <div className='md:mt-24 mt-10 space-y-10'>
            <div className='flex items-center space-x-4'>
              <p><BsFillTelephoneFill size={"25px"}></BsFillTelephoneFill></p>
              <p>
                +880 1757-136038<br/>
                +880 1756-193005
              </p>
            </div>
            <div className='flex items-center space-x-4'>
              <p><MdOutlineEmail size={"25px"}></MdOutlineEmail></p>
              <p className='z-10'>
                info@uniech.com
              </p>
            </div>
            <div className='flex items-center space-x-4'>
              <p><IoLocationOutline size={"25px"}></IoLocationOutline></p>
              <p className='z-10 '>
               House# 16 Rd # 5A, <br /> Uttara Sector 11, Dhaka 1230
              </p>
            </div>
          </div>
          <div className="flex md:mt-16 mt-10 py-4 items-center space-x-8">
            <a
              href="https://www.facebook.com/uniech.official"
              className="hover:text-[#1365FF] transition-colors duration-300 text-[#4CCEFF]"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/uniech/"
              className="hover:text-[#1365FF] transition-colors duration-300 text-[#4CCEFF]"
            >
              <GrLinkedin className='w-5 h-5'></GrLinkedin>
            </a>
          </div>
        </div>
        <ContactForm></ContactForm>
      </div>
    </section>
  )
}

export default Contact