import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, Slide } from 'react-toastify';


const ContactForm = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_10xoxiw", "template_3ek4nvo", form.current, 'EVvb_ohw8ghhC5AO5')
            .then(() => {
                e.target.reset()
                toast.success("Your message has been sent to our team.")
            }, (error) => {
                toast.error(error.text);
            });

    }

    return (
        <div className='col-start-4 col-span-8 pr-8 my-auto p-4 pb-40 md:pb-0'>

            <form ref={form} onSubmit={sendEmail}>
                <div className='md:grid grid-cols-2 my-10'>
                    <div>
                        <label className='block'>First Name</label>
                        <input className='bg-inherit border-b border-slate-800 w-full md:w-4/5 mt-3 focus:outline-none p-1' type="text" name='firstname' />
                    </div>
                    <div className='mt-10 md:mt-0'>
                        <label className='block'>Last Name</label>
                        <input className='bg-inherit border-b border-slate-800 w-full mt-3 focus:outline-none p-1' type="text" name='lastname' />
                    </div>
                    <div className='mt-10'>
                        <label className='block'>Email</label>
                        <input className='bg-inherit border-b border-slate-800 w-full md:w-4/5 mt-3 focus:outline-none p-1' type="text" name='email' />
                    </div>
                    <div className='mt-10'>
                        <label className='block'>Phone</label>
                        <input className='bg-inherit border-b border-slate-800 w-full mt-3 focus:outline-none p-1' type="tel" name='phone' />
                    </div>
                </div>
                <div className='mt-10'>
                    <label className='block'>Message</label>
                    <input className='bg-inherit border-b border-slate-800 w-full mt-3 focus:outline-none p-1' type="tel" name='message' />
                </div>
                <button type='submit' className='bg-[#04c] rounded w-1/2 md:w-1/4 text-center text-white p-3 ml-auto block mt-10'>Send Message
                </button>

            </form>

            <ToastContainer position="top-center" transition={Slide}
                draggable></ToastContainer>

        </div>
    )
}

export default ContactForm