import React from 'react';
import { Pricing } from '../../Components/Home/Pricing';
import { Footer } from '../../Components/Shared/Footer';
import Navbar from '../../Components/Shared/Navbar';

const ServicesPage = () => {
    return (
        <>
            <Navbar page></Navbar>
            <Pricing page></Pricing>
            <Footer></Footer>
        </>
    );
};

export default ServicesPage;